<template lang="pug">
  span.app-tooltip(
    v-b-tooltip="{ title, placement, container, boundary, trigger }"
    :class="{ 'app-icon-wrapper': useAppIcon }"
  )
    slot
      AppIcon(
        v-if="useAppIcon"
        :name="icon"
        :class="type"
      )
      FaIcon(
        v-else
        :icon="icon"
        :class="type"
        :spin="spin"
      )
</template>

<script>
  import { includes } from "lodash-es"

  const TRIGGER_TYPES = ["click", "hover", "focus"]
  const TOOLTIP_TYPES = ["default", "danger", "warning", "success"]

  export default {
    props: {
      title: {
        type: String,
        required: true
      },
      trigger: {
        type: String,
        default: "hover",
        validator: value => includes(TRIGGER_TYPES, value)
      },
      type: {
        type: String,
        default: "default",
        validator: value => includes(TOOLTIP_TYPES, value)
      },
      placement: {
        type: String,
        default: "top"
      },
      icon: {
        type: String,
        default: "question-circle"
      },
      container: {
        type: String,
        default: ""
      },
      boundary: {
        type: String,
        default: "scrollParent"
      },
      spin: {
        type: Boolean,
        default: false
      },
      useAppIcon: {
        type: Boolean,
        default: false
      }
    },

    components: {
      AppIcon: () => import("@/components/elements/AppIcon")
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/variables.sass"

  .app-tooltip
    margin-left: 10px

    svg
      font-size: 0.8rem

      &.default
        color: $default-gray-medium
        fill: $default-gray-medium

      &.warning
        color: $default-yellow

      &.danger
        color: $default-red

      &.success
        color: $default-green

  .app-icon-wrapper
    display: flex
    align-items: center
    justify-content: center
    svg
      display: inline-block
      height: 1em
      width: 1em
      margin-left: 0
</style>
